 
  <!-- 首页feed的每一项 -->
<template>
  <div class="TopstoryItem">
    <div
      class="ContentItem-title"
      @click="goToDetail(feedList.Id)"
      v-html="brightenKeyword(feedList.TopicTitle, keyword)"
    ></div>
    <feed-content
      ref="feedContent"
      v-if="true"
      :author="feedList"
      :summary="feedList.TopicContent"
      :content="feedList.TopicContent"
      :keyword="keyword"
      :isRead="false"
    ></feed-content>
    <feed-actions
      v-if="true"
      @changeReview="isReview = !isReview"
      :actions="feedList"
      :id="feedList.Id"
      :isDelete="isDelete"
    ></feed-actions>
    <transition name="fade">
      <comment
        v-if="isReview"
        :sectionId="feedList.SectionId"
        :id="feedList.TopicContent ? feedList.Id : 0"
      ></comment>
    </transition>
  </div>
</template>
<script>
import FeedContent from "@/components/bbs/FeedContent.vue";
import FeedActions from "@/components/bbs/FeedActions.vue";
import Comment from "@/components/bbs/Comment.vue";
import { mapGetters } from "vuex";

export default {
  name: "feed-item",
  components: {
    FeedContent,
    FeedActions,
    Comment,
  },
  props: {
    feedList: Object,
    isDelete: Boolean,
  },
  computed: {
    ...mapGetters(["searchContent"]),
  },
  watch: {
    searchContent: function (newValue) {
      this.keyword = newValue;
      console.log("更改关键词");
      console.log(newValue);
    },
  },
  data() {
    return {
      isReview: false,
      keyword: "",
    };
  },
  methods: {
    changeReview() {
      console.log("changeReview");
    },
    //进入详情页
    goToDetail(id) {
      var path ='/bbs/detail';
      let sUserAgent = navigator.userAgent.toLowerCase();
      if (
        /ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(
          sUserAgent
        )
      ) {
        //跳转移动端页面
         path = "/H5/bbs/detail";
      } 
      this.$router.push({
        path: path,
        query: { id: id },
      });
      console.log(id);
    },
    //搜索高亮
    brightenKeyword(val, keyword) {
      if (keyword.length > 0) {
        let keywordArr = keyword.split("");
        val = val + "";
        keywordArr.forEach((item) => {
          if (val.indexOf(item) !== -1 && item !== "") {
            val = val.replace(
              new RegExp(item, "g"),
              '<font color="#f75353">' + item + "</font>"
            );
          }
        });
        return val;
      } else {
        return val;
      }
    },
  },
};
</script>
<style lang='scss' scoped>
@import "../../assets/css/config";
.fade-enter-active,
.fade-leave-active {
  opacity: 1;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.TopstoryItem {
  background: #fff;
  border-bottom: 1px solid #f0f2f7;
  padding: 20px;
  .ContentItem-title {
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
  }
}
</style>