  <!-- 赞同评论等图标 -->
<template>
  <div class="ContentItem-actions">
    <div
      class="Vote-wrap"
      :class="{ ChangeStatus: actions.IsAgree }"
      @click="changeAgree"
    >
      <i class="el-icon-caret-top" style="font-size: 16px"></i>
      <span class="icon-down-fill-xs iconfont"></span>
      <span class="Vote-desc">
        {{
          actions.IsAgree
            ? `已赞同 ${actions.AgreeNum}`
            : `赞同 ${actions.AgreeNum}`
        }}
      </span>
    </div>
    <!-- <div class="Vote-wrap" :class="{ChangeStatus:actions.disagree}" @click="changeDisagree">
      <span class="icon-down-fill1-xs iconfont"></span>
    </div> -->
    <div class="Actions-wrap" @click="changeReview">
      <!-- <span class="iconfont icon-pinglun1"></span> -->
      <i class="el-icon-s-comment" style="font-size: 16px"></i>
      <!-- <span class="Actions-desc">{{
        isReview ? "收起评论" : `${actions.ReviewNum}条评论`
      }}</span> -->
      <span class="Actions-desc">{{
        isReview ? "收起" : `${actions.ReviewNum}`
      }}</span>
    </div>
    <!-- <div class="Actions-wrap">
      <span class="iconfont icon-xiaofeiji"></span>
      <span class="Actions-desc">分享</span>
    </div> -->
    <div class="Actions-wrap" @click="changeCollection">
      <!-- <span class="iconfont icon-shoucang "></span> -->
      <i
        :class="
          actions.IsCollection > 0 ? 'el-icon-star-on' : 'el-icon-star-off'
        "
        style="font-size: 16px"
      ></i>
      <span class="Actions-desc">{{ `收藏` }}</span>
    </div>
    <div
      v-if="isDelete"
      class="Actions-wrap"
      @click="deleteTopicFn(actions.Id)"
    >
      <i class="el-icon-delete-solid"></i>
      <!-- <span class="Actions-desc">{{ "删除" }}</span> -->
    </div>
    <!-- <div class="Actions-wrap">
      <span class="iconfont icon-shenglvehao"></span>
    </div> -->
  </div>
</template>
<script>
import { topicAgree, topicCollection, deleteTopic, queryStudentInfoById } from "@/api/bbs";
import { mapActions } from "vuex";
export default {
  name: "feed-action",
  props: {
    actions: Object,
    id: Number,
    isDelete: Boolean,
  },
  data () {
    return {
      isReview: false,
      user: {},
    };
  },
  created () {
    // id = this.$route.query.id;
    // this.getDetailMsg();
    var that = this;
    window["callByAndroidParam"] = function (jsonStr) {
      if (jsonStr != "") {
        that.user.Id = jsonStr.data;
        that.getQueryStudentInfoById();
      } else {
        this.$message.error("登录失败");
      }
    };
  },
  mounted () {
    if (localStorage.getItem("userInfo") != null) {
      this.user = JSON.parse(localStorage.getItem("userInfo"));
    } else {
      this.user.Id = 0;
    }
    console.log(this.isDelete)
    window.userIDResultByIos = this.userIDResultByIos;
  },
  inject: ["finishDelete"],
  methods: {
    ...mapActions(["setIdCollection"]),
    userIDResultByIos (jsonStr) {
      if (jsonStr != "") {
        this.user.Id = jsonStr.data;
        this.getQueryUserInfo();
      } else {
        this.$message.error("登录失败");
      }
    },
    callApp () {
      let u = navigator.userAgent;
      let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      if (window.android != null && typeof window.android != "undefined") {
        //可以传递自定义数据，比如json
        window.android.callAndroid(JSON.stringify({ action: "getUserID" }));
      } else if (isiOS) {
        window.webkit.messageHandlers.callIosGetUserID.postMessage(JSON.stringify({ action: "callIosGetUserID" }));
      } else {
        this.$message.error("请登录后再操作");
      }
    },
    //根据Id获取学生信息
    async getQueryStudentInfoById () {
      const res = await queryStudentInfoById(this.user.Id);
      this.user = res.response;
      console.log(this.user);
      localStorage.setItem("userInfo", JSON.stringify(this.user));
    },
    deleteTopicFn (id) {
      this.$confirm("此操作将永久删除该帖, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then((action, instance, done) => {
          if (action === "confirm") {
            this.postDeleteTopic(id);
          }
        })
        .catch(() => {
          this.$message.info("已取消删除");
        });
    },
    async postDeleteTopic (id) {
      const res = await deleteTopic(id);
      if (res.success == true) {
        this.finishDelete(id);
      }
    },
    //改变评论状态
    changeReview () {
      this.$emit("changeReview", !this.isReview);
      this.isReview = !this.isReview;
    },
    async postTopicAgree () {
      // 点赞/取消点赞
      let parm = {};
      parm.TopicId = this.id;
      parm.UserId = this.user.Id;

      const res = await topicAgree(parm);
      console.log(res);
      if (res.success == true) {
        if (this.actions.IsAgree) {
          this.actions.AgreeNum--;
        } else {
          this.actions.AgreeNum++;
        }
        this.actions.IsAgree = !this.actions.IsAgree;
      }
    },
    //改变赞同状态
    changeAgree () {
      if (this.user.Id == 0) {
        this.callApp();
        return false;
      }
      this.postTopicAgree();
    },
    async postTopicCollection () {
      // 点赞/取消点赞
      let parm = {};
      parm.TopicId = this.id;
      parm.UserId = this.user.Id;

      const res = await topicCollection(parm);
      console.log(res);
      if (res.success == true) {
        this.actions.IsCollection = !this.actions.IsCollection;
        this.setIdCollection(this.id)
      }
    },
    changeCollection () {
      if (this.user.Id == 0) {
        this.callApp();
        return false;
      }
      this.postTopicCollection();
    },
    changeDisagree () {
      if (this.actions.disagree) {
        //取消踩
        this.axios.get(`/unsupport_cancel?type=2&id=${this.id}`).then((res) => {
          if (res.status == 200) {
            this.actions.disagree = !this.actions.disagree;
          }
        });
      } else {
        //踩
        this.axios.get(`/unsupport?type=2&id=${this.id}`).then((res) => {
          if (res.status == 200) {
            this.actions.disagree = !this.actions.disagree;
            if (this.actions.IsAgree) {
              this.actions.IsAgree = false;
              this.actions.AgreeNum--;
            }
          }
        });
      }
    },
  },
};
</script>
<style lang='scss' scoped>
@import "../../assets/css/config.scss";
.ContentItem-actions {
  display: flex;
  align-items: center;
  padding-top: 10px;
  color: #646464;
  background: #fff;
  .Vote-wrap {
    height: 32px;
    line-height: 32px;
    padding: 0 10px;
    color: $mainColor;
    background: rgba(0, 132, 255, 0.1);
    font-size: 14px;
    margin-right: 4px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .Actions-wrap {
    display: flex;
    align-items: center;
    color: $fontColor;
    font-size: 14px;
    margin-left: 24px;
    cursor: pointer;
  }
  .Actions-desc,
  .Vote-desc {
    margin-left: 4px;
  }
  .ChangeStatus {
    color: #fff;
    background: $mainColor;
  }
  .el-icon-star-on {
    color: #0084ff;
  }
}
</style>