  <!-- 首页摘要 -->
<template>
  <div class="RichContent-wrapper">
    <div class="RichContent-inner">
      <template v-if="!isRead">
        <!-- <b>{{author}}</b> -->
        <span v-html="brightenKeyword(summary, keyword)"></span>
        <span class="ContentItem-more" @click="isRead = !isRead" v-if="isShow"
          >阅读全文</span
        >
        <span class="iconfont icon-arrow-down" v-if="isShow"></span>
      </template>
      <rich-content
        v-else
        @collapseText="isRead = !isRead"
        :content="author"
      ></rich-content>
    </div>
  </div>
</template>
<script>
import RichContent from "@/components/bbs/RichContent.vue";
import FeedActions from "@/components/bbs/FeedActions.vue";
export default {
  name: "feed-content",
  components: {
    RichContent
  },
  // props:{
  //   author:String,
  //   summary:String
  // },
  props: {
    author: Object,
    summary: String,
    content: String,
    keyword: String,
    isRead: Boolean
  },
  data () {
    return {
      // isRead: false,
      isShow: true
    };
  },
  mounted () {
    //   console.log(this.content);
    // if(this.content.length<36){
    //   this.isShow=false;
    // }
  },
  methods: {
    //搜索高亮
    brightenKeyword (val, keyword) {
      if (val.length > 50) {
        return this.content.substring(0, 200) + "......";
      } else {
        return this.content;
      }
    }
  }
};
</script>
<style lang='scss' scoped>
/deep/.RichContent-inner {
  font-size: 15px;
  margin-top: 9px;
  img {
    max-width: 100%;
  }
  .ContentItem-more {
    margin-left: 4px;
    color: #175199;
    font-size: 14px;
    cursor: pointer;
  }
  .icon-arrow-down {
    color: #175199;
    font-size: 18px;
    font-weight: 600;
  }
}
</style>